<template>
    <div class="text-center">
        <font-awesome-icon class="text-primary mb-3" icon="envelope" size="5x" />
        <template v-if="has_success">
            <h1 class="fs-3 fs-light mb-3">Verificatie geslaagd</h1>
            <div class="m-auto text-muted">
                <p>Je account is zojuist geactiveerd. Klik op de onderstaande knop om in te loggen.</p>
                <b-button :to="login_to" variant="primary">Ga verder naar inloggen</b-button>
            </div>
        </template>
        <template v-else>
            <template v-if="has_error">
                <h1 class="fs-3 fs-light mb-3">Geactiveerd of niet gevonden</h1>
                <p>Je account is reeds geactiveerd of kan niet worden gevonden.</p>
                <b-row>
                    <b-col cols="12" lg="6" class="mb-3">
                        <b-button :to="login_to" variant="primary" block>Inloggen</b-button>
                    </b-col>
                    <b-col cols="12" lg="6" class="mb-3">
                        <b-button :to="register_to" variant="primary" block>Registeren</b-button>
                    </b-col>
                </b-row>
            </template>
            <template v-else>
                <h1 class="fs-3 fs-light mb-3">Wachten op e-mail verificatie</h1>
                <div class="m-auto text-muted">
                    <p>Je ontvangt per e-mail een link om je e-mailadres te verifiëren.</p>
                    <p>Ga naar je e-mailinbox, verifieer je e-mailadres en volg de instructies.</p>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    //import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default ({
        components: {
            //ValidationObserver,
            //ValidationProvider,
        },
        props: {
            login_to: {
                type: [String, Boolean],
                default: '/account/login'
            },
            register_to: {
                type: [String, Boolean],
                default: '/account/register'
            },
        },
        data() {
            return {
                has_success: false,
                has_error: false,
                form: {
                    token: '',
                    email: '',
                }
            }
        },
        methods: {
            verifyAccount: function() {
                this.has_success = false;
                this.has_error = false;
                
                this.$axios.post("https://api.tixgo.nl/customer/verify", this.form)
                    .then( response => {
                        if (response.data.status == 'success') {
                            this.has_success = true;
                        } else {
                            this.has_error = true;
                        }
                    }
                );
            }
        },
        created() {
            if (this.$route.query.token && this.$route.query.email) {

                this.form.token = this.$route.query.token;
                this.form.email = this.$route.query.email;
                
                this.verifyAccount();
            }
        }
    });
</script>